import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import { Form } from '../../components/Form';

export const Profile = () => {
  return (
    <Container maxWidth="md" sx={{ pt: 2 }}>
      <Typography variant="h5" component="h1" align="center">
        This is a page with form to add or update profile of expert
      </Typography>
      <Box width={1} display="flex" justifyContent="center">
        <Form />
      </Box>
    </Container>
  );
};