import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import {useState} from 'react';

import {ICategory, IProfession} from 'types';

interface NewProfessionDialogProps {
  selectedCategory: ICategory;
  setSelectedProfession: React.Dispatch<React.SetStateAction<IProfession | null>>;
  setCategories: React.Dispatch<React.SetStateAction<ICategory[]>>;
  initialValue?: string;
  onClose: () => void;
}

export const NewProfessionDialog = (props: NewProfessionDialogProps) => {
  const {
    setCategories,
    setSelectedProfession,
    selectedCategory,
    initialValue = '',
    onClose,
  } = props;

  const [inputValue, setInputValue] = useState(initialValue);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const onAdd = () => {
    const newProfession = {
      nickname: inputValue.trim(),
      label: inputValue.trim(),
      id: 0,
      categoryLabel: selectedCategory.label,
      categoryId: selectedCategory.id,
    };

    setCategories((cats) => {
      return [...cats].map((category) => {
        return selectedCategory.label === category.label ? {
          ...category,
          professions: [
            newProfession,
            ...category.professions
          ]
        } : category;
      });
    });
    setSelectedProfession(newProfession);
    onClose();
  };

  return (
    <Dialog
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      open
    >
      <DialogContent>
        <DialogContentText>
          Добавить профессию
        </DialogContentText>
        <TextField
          onChange={onChange}
          value={inputValue}
          margin="dense"
          fullWidth
          autoFocus
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Отмена</Button>
        <Button onClick={onAdd}>Добавить</Button>
      </DialogActions>
    </Dialog>
  );
}