import { useEffect, useRef } from 'react';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import 'intl-tel-input/build/js/utils.js';
import {UseFormSetValue} from "react-hook-form";
import {FormFields} from "../../types";

interface PhoneInputProps {
  setValue: UseFormSetValue<FormFields>;
}

export const PhoneInput = ({ setValue }: PhoneInputProps) => {
  const iti = useRef<ReturnType<typeof intlTelInput> | null>(null);
  const phoneNumberInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    iti.current = intlTelInput(phoneNumberInputRef.current as HTMLInputElement, {
      utilsScript: 'intl-tel-input/build/js/utils.js',
      preferredCountries: [],
      initialCountry: 'ru',
      hiddenInput: 'phone',
      separateDialCode: true,
      nationalMode: false,
    });

    const inputRef = phoneNumberInputRef.current as HTMLInputElement;

    const validPhoneNumberHandler = () => {
      setValue('phoneNumber',{
        isValid: iti.current?.isValidNumber() || false,
        phone: iti.current?.getNumber() || '',
      }, {shouldValidate: true});
    };

    inputRef.addEventListener('input', validPhoneNumberHandler);

    return () => {
      inputRef.removeEventListener('input', validPhoneNumberHandler)
      iti.current?.destroy();
    };
  }, [setValue]);

  return (
    <input
      ref={phoneNumberInputRef}
      type="tel"
      required={true}
    />
  );
};