import {FC, useEffect, useMemo, useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {Controller, FieldErrors} from 'react-hook-form';
import Box from '@mui/material/Box';

import {ICity, ICountry, FormControl, FormFields} from 'types';
import { getCountries, getCities } from 'api';
import ErrorMessage from "../ErrorMessage";

interface CountryRegionCityProps {
  setSelectedCountryCities: React.Dispatch<React.SetStateAction<ICity[]>>;
  control: FormControl;
  errors: FieldErrors<FormFields>;
}
export const CountryRegionCity: FC<CountryRegionCityProps> = ({control, errors, setSelectedCountryCities}) => {

  const [selectedCountry, setSelectedCountry] = useState<ICountry | null>(null);
  const [countries, setCountries] = useState<ICountry[]>([]);

  const [selectedCity, setSelectedCity] = useState<ICity | null>(null);
  const [cities, setCities] = useState<ICity[]>([]);

  const onSelectNewCountry = (newCountry: ICountry) => {
    setSelectedCountry(newCountry);
    setSelectedCity(null);
  };

  const onSelectNewCity = (newCity: ICity) => {
    setSelectedCity(newCity);
  };

  useEffect(() => {
    Promise.all([
      getCountries(),
      getCities(),
    ]).then(([countries, cities]) => {
      setCountries(countries);
      setCities(cities);
    });
  }, []);

  const filteredCites = useMemo(() => {
    if (!selectedCountry) return [];

    return cities.filter((city) => city.countryId === selectedCountry.id);
  }, [cities, selectedCountry]);

  useEffect(() => {
    setSelectedCountryCities(filteredCites);
  }, [setSelectedCountryCities, filteredCites]);
  
  return (
    <Box
      mb={3}
      display="flex"
      justifyContent="space-between"
    >
      <div>
        <Controller
          name="country"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              sx={{ width: 300 }}
              value={selectedCountry}
              disabled={countries.length === 0}
              onChange={(_, item) => {
                onSelectNewCountry(item as ICountry);
                field.onChange(item);
              }}
              options={countries}
              renderInput={(params) => (
                <TextField {...params} label="Страна" required={true}/>
              )}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
            />
          )}
        />
        <ErrorMessage error={errors.country}/>
      </div>
      <div>
        <Controller
          name="city"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              sx={{ width: 300 }}
              value={selectedCity}
              disabled={countries.length === 0 || cities.length === 0 || !selectedCountry}
              onChange={(_, item) => {
                onSelectNewCity(item as ICity)
                field.onChange(item);
              }}
              options={filteredCites}
              renderInput={(params) => (
                <TextField {...params} label="Город" required={true}/>
              )}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
            />
          )}
        />
        <ErrorMessage error={errors.city}/>
      </div>
    </Box>
  );
};