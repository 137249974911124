import ThemeProvider from '@mui/material/styles/ThemeProvider';
import GlobalStyles from '@mui/material/GlobalStyles';
import CssBaseline from '@mui/material/CssBaseline';
import { RouterProvider } from 'react-router-dom';
import { Fragment } from 'react';

import { globalStyles } from './styles';
import { router } from './router';
import { theme } from './theme';

export const App = () => {
  return (
    <Fragment>
      <GlobalStyles styles={globalStyles} />
      <CssBaseline />
        <ThemeProvider theme={theme}>
          <RouterProvider router={router} />
        </ThemeProvider>
    </Fragment>
  );
};
