import { css } from '@emotion/react';

export const globalStyles = css`
  .iti.iti--allow-dropdown {
    margin-bottom: 0;
    width: 100%;
  }
  
  .iti__country-list {
    background-color: #fff;
    border: 1px solid #CCC;
    max-width: 300px;
  }
  
  .iti input {
    border: 1px solid #DFDFDF;
    border-radius: 8px;
    padding: 10px 8px;
    box-shadow: none;
    line-height: 1.3;
    font-size: 18px;
    color: #555;
    outline: none;
    height: 40px;
    width: 100%;
  }
  
  .iti__country-name {
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    width: 250px;
  }
`;