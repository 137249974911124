import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {Controller, useFieldArray} from 'react-hook-form';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Autocomplete from '@mui/material/Autocomplete';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import {FC, useState} from 'react';
import Box from '@mui/material/Box';

import {ICity, FormControl} from 'types';
import {getUniqId} from 'utils';

interface ServicePointsProps {
  control: FormControl;
  cities: ICity[];
}

export const ServicePoints: FC<ServicePointsProps> = ({cities, control}) => {
  const [selectedCities, setSelectedCities] = useState<ICity[]>([]);

  const {fields, append, remove} = useFieldArray({
    rules: {maxLength: 20},
    name: 'offlinePoints',
    control,
  });

  return (
    <Box my={3}>
      <Controller
        name="offlineCities"
        control={control}
        render={({field}) => (
          <Autocomplete
            {...field}
            multiple
            options={cities}
            value={selectedCities}
            filterSelectedOptions
            onChange={(_, item) => {
              field.onChange(item);
              setSelectedCities(item as ICity[]);
            }}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField {...params} label="Города предоставления услуг"/>
            )}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
          />
        )}
      />
      {fields.map((field, index) => (
        <Box key={field.id} mt={2} maxWidth="50%" width={1} sx={{display: 'flex', alignItems: 'center', mb: 1}}>
          <Controller
            control={control}
            name={`offlinePoints.${index}.value`}
            render={({field}) => <TextField fullWidth {...field} variant="outlined" label={'Ссылка на геопозицию'}/>}
          />
          {index === fields.length - 1 ? (
            <IconButton sx={{ml: 1}} size="large" onClick={() => append({value: '', pointId: getUniqId()})}>
              <AddCircleOutlineIcon/>
            </IconButton>
          ) : (
            <IconButton sx={{ml: 1}} size="large" onClick={() => remove(index)}>
              <RemoveCircleOutlineIcon/>
            </IconButton>
          )}
        </Box>
      ))}
    </Box>
  );
};