import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { useState } from 'react';

import { ICategory } from 'types';

interface NewCategoryDialogProps {
  setSelectedCategory: React.Dispatch<React.SetStateAction<ICategory | null>>;
  setCategories: React.Dispatch<React.SetStateAction<ICategory[]>>;
  initialValue?: string;
  onClose: () => void;
}

export const NewCategoryDialog = ({
  setSelectedCategory,
  initialValue = '',
  setCategories,
  onClose,
}: NewCategoryDialogProps) => {
  const [inputValue, setInputValue] = useState(initialValue);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const onAdd = () => {
    const category = {
      label: inputValue.trim(),
      professions: [],
      nickname: '',
      id: 0,
    };

    setCategories((cats) => {
      const categories = [...cats];
      return [category, ...categories];
    });
    setSelectedCategory(category);
    onClose();
  };

  return (
    <Dialog open fullWidth maxWidth="sm" onClose={onClose}>
      <DialogContent>
        <DialogContentText>
          Добавить новую категорию
        </DialogContentText>
        <TextField
          onChange={onChange}
          value={inputValue}
          margin="dense"
          fullWidth
          autoFocus
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Отмена</Button>
        <Button 
          disabled={inputValue.trim().length === 0}
          onClick={onAdd}
        >
          Добавить
        </Button>
      </DialogActions>
    </Dialog>
  );
}