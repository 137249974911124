import axios from 'axios';
import {
  IProfessionCategoriesResponse,
  IProfessionsResponse,
  ICountriesResponse,
  ICitiesResponse,
  ICategory,
  ICountry,
  ICity, CreateProfile,
} from 'types';

const transport = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL || 'https://bot.ws2you.com/api/',
  timeout: 120000,
});

export const getCategories = (): Promise<ICategory[]> => {
  return Promise.all([
    transport.get<IProfessionCategoriesResponse>('profession-categories'),
    transport.get<IProfessionsResponse>('professions')
  ]).then(([{ data: categories }, { data: professions }]) => {
    if (categories.code !== 200 || professions.code !== 200) {
      throw new Error(categories.code.toString());
    }

    return categories.objects.map((category) => ({
      professions: professions.objects.reduce<ICategory['professions']>((acc, curr) => {
        const cat = curr.categories.find(({ id }) => category.id === id);
        if (cat) {
          acc.push({
            categoryLabel: category.name,
            categoryId: category.id,
            label: curr.name,
            id: curr.id,
          });
        }

        return acc;
      }, []),
      nickname: category.nickname,
      label: category.name,
      id: category.id,
    }));
  });
};

export const getCountries = (): Promise<ICountry[]> => {
  return transport.get<ICountriesResponse>('countries')
    .then(({ data }) => {
      if (data.result) {
        return data.objects
          .map((country) => ({
            label: country.name,
            id: country.id,
          }));
      }

      throw new Error(`${data.code}`);
    });
};

export const getCities = (): Promise<ICity[]> => {
  return transport.get<ICitiesResponse>('cities')
    .then(({ data }) => {
      if (data.result) return data.objects
        .map((city) => ({
          countryId: city.country.id,
          label: city.name,
          id: city.id,
        }));

      throw new Error(`${data.code}`);
    });
};

export const getStatuses = () => {
  return transport.get('statuses')
    .then(({ data }) => console.log(data))
};

export const createProfile = (data: CreateProfile) => {
  return transport.post('save-profile', { data }, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }});
}