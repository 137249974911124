import { createBrowserRouter } from 'react-router-dom';
import { lazy, Suspense } from 'react';

import { NotFound } from './pages/NotFound';
import { Profile } from './pages/Profile';

const Admin = lazy(() => import('./pages/Admin'));

export const router = createBrowserRouter([
  {
    path: '/admin',
    element: (
      <Suspense fallback={null}>
        <Admin />
      </Suspense>
    ),
  },
  {
    path: '/',
    element: <Profile />
  },
  {
    path: '*',
    element: <NotFound />
  }
]);