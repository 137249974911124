import { useForm, Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useState } from 'react';
import {yupResolver} from "@hookform/resolvers/yup";
import {FormFields, ICity, CreateProfile, WorkFormatTag} from 'types';
import { getUniqId } from 'utils';
import {
  CategoryProfession,
  CountryRegionCity,
  ServiceFormat,
  ServicePoints,
  PhoneInput,
} from 'components/index';
import ErrorMessage from "../ErrorMessage";
import {array, boolean, mixed, object, string} from 'yup';
import { createProfile } from 'api';

const schema = object({
  name: string().required('Обязательное поле'),
  description: string(),
  phoneNumber: object({
    isValid: boolean().oneOf([true], 'Неправильный формат телефона'),
    phone: string().required('Обязательное поле'),
  }),
  format: array().min(1, 'Обязательное поле'),

  category: mixed().required('Обязательное поле'),
  profession: mixed().required('Обязательное поле'),
  city: mixed().required('Обязательное поле'),
  country: mixed().required('Обязательное поле'),
});

export const Form = () => {
  const [selectedCountryCities, setSelectedCountryCities] = useState<ICity[]>([]);

  const {control, handleSubmit, setValue, formState: {isSubmitting, errors}, watch} = useForm<FormFields>({
    defaultValues: {
      offlinePoints: [{value: '', pointId: getUniqId()}],
      phoneNumber: {isValid: false, phone: ''},
      name: '',
      description: '',
      format: [],
    },
    resolver: yupResolver(schema as any)
  });

  const onSubmit = (data: FormFields) => {
      console.log(data, 'data')
      const result: CreateProfile = {
        name: data.name,
        phoneNumber: data.phoneNumber.phone,
        description: data.description,
        profession: JSON.stringify({
          ...(String(data.profession.id) === "0"
            ? {profession: data.profession.label}
            : {id: String(data.profession.id)}),
          ...(String(data.profession.categoryId) === "0"
            ? {category: data.profession.categoryLabel}
            : {categoryId: String(data.profession.categoryId)}),
        }),
        cities: [String(data.city.id)],
        workFormats: (data.format || []).reduce((acc, curr) => {
          acc[curr.tag] = curr.tag !== 'offline' ? '1' : {
            geoPoints: (data.offlinePoints.filter((point) => Boolean(point.value.trim())).reduce((acc, curr) => {
              acc[curr.pointId] = curr.value;
              return acc;
            }, {} as Record<string | number, string>)),
            cities: [...data?.offlineCities.map((city) => city.id)],
          }

          return acc;
        }, {} as Record<WorkFormatTag, unknown>),
      };
      console.log(result, 'result data')

      createProfile(result)
        .then(console.log)
        .catch(console.log);
  };

  const format = watch('format') || [];
  const isServicePointsOpen = format.some((format) => format.tag === 'offline');

  return (
    <Box
      sx={{width: 1}}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        name="name"
        control={control}
        defaultValue=""
        render={({field}) => (
          <TextField
            {...field}
            inputProps={{maxLength: 255}}
            sx={{my: 2.5}}
            label="Имя"
            fullWidth
            required
          />
        )}
      />
      <ErrorMessage error={errors.name}/>
      <Box mb={2.5} maxWidth={300} width={1} display="flex">
        <div>
          <PhoneInput
            setValue={setValue}
          />
          {errors.phoneNumber?.phone ? (
            <ErrorMessage error={errors.phoneNumber?.phone}/>
          ) : (
            <ErrorMessage error={errors.phoneNumber?.isValid}/>
          )}
        </div>
      </Box>
      <CategoryProfession
        control={control}
        errors={errors}
        setValue={setValue}
      />
      <CountryRegionCity
        control={control}
        errors={errors}
        setSelectedCountryCities={setSelectedCountryCities}
      />
      <ServiceFormat
        control={control}
        errors={errors}
      />
      {isServicePointsOpen && selectedCountryCities.length > 0 && (
        <ServicePoints
          cities={selectedCountryCities}
          control={control}
        />
      )}
      <Controller
        name="description"
        control={control}
        defaultValue=""
        render={({field}) => (
          <TextField
            {...field}
            inputProps={{maxLength: 1000}}
            label="Описание"
            sx={{mb: 2}}
            fullWidth
            multiline
            rows={4}
          />
        )}
      />
      <Button
        disabled={isSubmitting}
        variant="contained"
        color="primary"
        type="submit"
      >
        Отправить
      </Button>
    </Box>
  );
};
