import {FC, useEffect, useMemo, useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {Controller, FieldErrors, UseFormSetValue} from 'react-hook-form';
import Box from '@mui/material/Box';
import {ICategory, FormControl, IProfession, FormFields} from 'types';
import {getCategories} from 'api';
import {NewProfessionDialog} from 'components/NewProfessionDialog';
import {NewCategoryDialog} from 'components/NewCategoryDialog';
import ErrorMessage from "../ErrorMessage";

interface CategoryProfessionProps {
  control: FormControl;
  errors: FieldErrors<FormFields>;
  setValue: UseFormSetValue<FormFields>;
}

const newCategory = {
  nickname: 'add_new_category',
  label: 'Добавить категорию',
  professions: [],
  id: 999999999,
};

const newProfession = {
  nickname: 'add_new_profession',
  label: 'Добавить профессию',
  id: 999999999,
};

export const CategoryProfession: FC<CategoryProfessionProps> = ({control, errors, setValue}) => {
  const [selectedProfession, setSelectedProfession] = useState<IProfession | null>(null);
  const [isOpenNewProfessionDialog, setIsOpenNewProfessionDialog] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<ICategory | null>(null);
  const [isOpenNewCategoryDialog, setIsOpenNewCategoryDialog] = useState(false);
  const [professionInputValue, setProfessionInputValue] = useState('');
  const [categoryInputValue, setCategoryInputValue] = useState('');
  const [categories, setCategories] = useState<ICategory[]>([]);

  const onSelectNewCategory = (newCategory: ICategory) => {
    setSelectedCategory(newCategory);
    setSelectedProfession(null);
  };


  const onSelectNewProfession = (newProfession: IProfession) => {
    setSelectedProfession(newProfession);
  };

  const professions = useMemo(() => {
    const categoryProfessions = categories.find((cat) => cat.id === selectedCategory?.id)?.professions || [];

    return [...categoryProfessions, {
      ...newProfession,
      categoryLabel: selectedCategory?.label,
      categoryId: selectedCategory?.id,
    }];
  }, [categories, selectedCategory]);

  useEffect(() => {
    getCategories()
      .then((categories) => setCategories([...categories, newCategory]))
      .catch(console.log);
  }, []);

  useEffect(() => {
    if (!selectedCategory) return;

    if (selectedCategory.label === newCategory.label) {
      setIsOpenNewCategoryDialog(true);
    }
  }, [categories, selectedCategory]);

  useEffect(() => {
    if (!selectedProfession) return;

    if (selectedProfession.label === newProfession.label) {
      setIsOpenNewProfessionDialog(true);
    }
  }, [selectedProfession]);

  return (
    <Box
      mb={3}
      display="flex"
      justifyContent="space-between"
    >
      <div>
        <Controller
          name="category"
          control={control}
          render={({field}) => (
            <Autocomplete
              {...field}
              sx={{width: 300}}
              value={selectedCategory}
              inputValue={categoryInputValue}
              disabled={categories.length === 0}
              onChange={(_, item) => {
                onSelectNewCategory(item as ICategory);
                field.onChange(item);
              }}
              options={categories}
              renderInput={(params) => (
                <TextField {...params} label="Категория" required={true}/>
              )}
              onInputChange={(_, newInputValue) => setCategoryInputValue(newInputValue)}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
            />
          )}
        />
        <ErrorMessage error={errors.category}/>
      </div>
      <div>
        <Controller
          name="profession"
          control={control}
          render={({field}) => (
            <Autocomplete
              {...field}
              sx={{width: 300}}
              value={selectedProfession}
              inputValue={professionInputValue}
              disabled={!selectedCategory}
              onChange={(_, item) => {
                onSelectNewProfession(item as IProfession);
                field.onChange(item);
              }}
              options={professions}
              renderInput={(params) => (
                <TextField {...params} label="Профессия" required={true}/>
              )}
              onInputChange={(_, newInputValue) => setProfessionInputValue(newInputValue)}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
            />
          )}
        />
        <ErrorMessage error={errors.profession}/>
      </div>
      {isOpenNewProfessionDialog && selectedCategory && (
        <NewProfessionDialog
          onClose={() => setIsOpenNewProfessionDialog(false)}
          setSelectedProfession={(newProfession) => {
            setSelectedProfession(newProfession);
            setValue('profession', newProfession as any, {shouldValidate: true});
          }}
          setCategories={setCategories}
          selectedCategory={selectedCategory}
        />
      )}
      {isOpenNewCategoryDialog && (
        <NewCategoryDialog
          onClose={() => setIsOpenNewCategoryDialog(false)}
          setSelectedCategory={(newCategory) => {
            setSelectedCategory(newCategory);
            setValue('category', newCategory as any, {shouldValidate: true});
          }}
          setCategories={setCategories}
        />
      )}
    </Box>
  );
};