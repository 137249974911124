import Autocomplete from '@mui/material/Autocomplete';
import {FC, useState} from 'react';
import TextField from '@mui/material/TextField';
import {Controller, FieldErrors} from 'react-hook-form';
import Box from '@mui/material/Box';

import {FormControl, FormFields, WorkFormatTag} from 'types';
import ErrorMessage from "../ErrorMessage";

interface IOption {
  tag: WorkFormatTag;
  label: string;
}

const options: IOption[] = [
  {label: 'Онлайн', tag: 'online'},
  {label: 'Очно', tag: 'offline'},
  {label: 'Выездной', tag: 'client'},
];

interface ServiceFormatProps {
  control: FormControl;
  errors: FieldErrors<FormFields>;
}

export const ServiceFormat: FC<ServiceFormatProps> = ({control, errors}) => {

  const [selectedFormats, setSelectedFormats] = useState<IOption[]>([]);

  return (
    <Box mb={3}>
      <div>
        <Controller
          name="format"
          control={control}
          render={({field}) => (
            <Autocomplete
              {...field}
              multiple
              options={options}
              value={selectedFormats}
              filterSelectedOptions
              onChange={(_, item) => {
                field.onChange(item);
                setSelectedFormats(item as IOption[]);
              }}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField {...params} label="Формат *"/>
              )}
              isOptionEqualToValue={(option, value) => option?.tag === value?.tag}
            />
          )}
        />
        <ErrorMessage error={errors.format}/>
      </div>
    </Box>
  );
};